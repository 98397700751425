import { getBaseUri } from "../Config";
import { getToken, removeToken } from "./storageUtil";

const getHttpHeaders = () => {
  const token = getToken();
  return token
    ? {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
      }
    : {
        "content-type": "application/json",
      };
};

export const executeSecureGet = async (targetUrl) => {
  var baseUri = await getBaseUri();
  const response = await fetch(`${baseUri}/${targetUrl}`, {
    method: "GET",
    mode: "cors",
    headers: getHttpHeaders(),
  });

  if (response.status === 401) {
    removeToken();
    window.location.href = "/";
  }

  if (response.ok) {
    const result = await response.json();
    return result.data;
  }

  return null;
};
