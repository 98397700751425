import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig } from "./Config";
import { usePromiseTracker } from "react-promise-tracker";
import LoadingOverlay from "react-loading-overlay";

getMsalConfig().then((configuration) => {
  const pca = new PublicClientApplication(configuration);

  const AppProvider = () => (
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  );

  const IndexApp = () => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      <LoadingOverlay active={promiseInProgress} spinner>
        <AppProvider />
      </LoadingOverlay>
    );
  };

  ReactDOM.render(<IndexApp />, document.getElementById("root"));

  registerServiceWorker();
});
