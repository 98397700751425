import React, { useState } from "react";
import { getScopes } from "./Config";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { executeGetMenu } from "./actions/menuActions";
import { executeGetUserData } from "./actions/authenticationActions";
import { setToken } from "./lib/storageUtil";
import { useMsal } from "@azure/msal-react";

export default () => {
  const [menu, setMenu] = useState(null);

  const { instance, accounts } = useMsal();

  const isLoggedIn = accounts.length > 0;

  const logoutAction = () => {
    instance.logoutPopup();
  };

  const loginAction = () => {
    instance.loginRedirect({
      prompt: "select_account",
    });
  };

  const acquireToken = async () => {
    const scopes = await getScopes();
    const account = accounts[0];
    const response = await instance.acquireTokenSilent({
      scopes: scopes,
      account: account,
    });
    if (response) {
      setToken(response.accessToken);
    }
  };

  const getMenuAction = async () => {
    await acquireToken();
    const menuData = await executeGetMenu();
    setMenu(menuData);
  };

  const getUserData = async () => {
    await acquireToken();
    const userData = await executeGetUserData();
    return userData;
  };

  if (isLoggedIn) {
    return (
        <Dashboard
          getUserData={getUserData}
          menu={menu}
          logoutAction={logoutAction}
          getMenuAction={getMenuAction}
        />
    );
  } else {
    return (
        <Login loginAction={loginAction}></Login>
    );
  }
};
