import React from "react";
import { getToken } from "../lib/storageUtil";
import { isMobile } from 'react-device-detect';

const openLink = async (event, link, tipoAcceso, marketURL, marketURL_IOS, link_IOS, link_Android) => {
  event.preventDefault();
  if (tipoAcceso === "SSO") {
    window.open(`${link}?t=${getToken()}`);
  } else if (tipoAcceso === "APP_MOBILE") {

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
      openMobile(marketURL, link_Android);
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      openMobile(marketURL_IOS, link_IOS);
    }

  } else {
    window.open(link);
  }
};

const openMobile = async (market, link) => {

  var fallbackToStore = function () {
    window.location.replace(market);
  };

  var openApp = function () {
    window.location.replace(link);
  };

  openApp();

  setTimeout(fallbackToStore, 250);

};

const renderNavMenuItem = ({ name, link, menuImage, tipoAcceso, marketURL, marketURL_IOS, link_IOS, link_Android }) => {
  return (
    <a
      key={name}
      href=""
      onClick={(event) => openLink(event, link, tipoAcceso, marketURL, marketURL_IOS, link_IOS, link_Android)}
    >
      <img src={menuImage} alt={name} />
      {name}
    </a>
  );
};

const renderMainMenuItem = ({ name, link, dashboardImage, tipoAcceso, marketURL, marketURL_IOS, link_IOS, link_Android }) => {
  return (
    <div className="col-6" key={name}>
      <a
        key={name}
        className="access__item siu"
        href=""
        onClick={(event) => openLink(event, link, tipoAcceso, marketURL, marketURL_IOS, link_IOS, link_Android)}
      >
        <img src={dashboardImage} alt={name} />
        <h2>{name}</h2>
      </a>
    </div>
  );
};

const orderByPrioridad = (a, b) => b.prioridad - a.prioridad;

export const renderMainMenu = (menuItems) => {
  return (
    menuItems &&
    menuItems
      .filter((m) => (m.showInHome && (m.tipoAcceso != "APP_MOBILE" || (isMobile && m.tipoAcceso === "APP_MOBILE"))))
      .sort(orderByPrioridad)
      .map(renderMainMenuItem)
  );
};

export const renderNavMenu = (menuItems) => {
  return menuItems && menuItems
  .filter((m) => (m.tipoAcceso != "APP_MOBILE" || (isMobile && m.tipoAcceso === "APP_MOBILE")))
  .sort(orderByPrioridad)
  .map(renderNavMenuItem);
};
