import jwt_decode from "jwt-decode";
import { getToken, removeToken } from "../lib/storageUtil";
import { executeSecureGet } from "../lib/httpUtil";

const cleanKey = (key) => (key.includes("/") ? key.split("/").pop() : key);

const extractTokenData = (token) =>
  Object.entries(token).reduce(
    (acum, [key, value]) => ({ ...acum, [cleanKey(key)]: value }),
    {}
  );

export const executeRestoreSession = () => {
  const existingToken = getToken();
  if (existingToken) {
    return extractTokenData(jwt_decode(existingToken));
  }
  return null;
};

export const executeLogout = () => {
  removeToken();
  window.location.href = "/";
};

export const executeGetUserData = async () => {
  return await executeSecureGet("api/v1/user");
};
