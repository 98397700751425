import React, { useEffect, useState } from "react";
import { renderMainMenu, renderNavMenu } from "../components/menu";

export default ({ getUserData, menu, logoutAction, getMenuAction }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [user, setUser] = useState({
    name: "",
    lastName: "",
    roles: [],
  });


  useEffect(() => {
    async function loadMenu() {
      await getMenuAction();
    }

    async function loadUserData() {
      const userData = await getUserData();
      setUser(userData);
    }

    loadUserData();
    loadMenu();
  }, []);

  return (
    <div id="dashboard">
      <header>
        <div className="navbar__primary">
          <div
            className="navbar__primary__open"
            onClick={() => setShowMenu(true)}
          >
            <svg
              width="29"
              height="19"
              viewBox="0 0 29 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.11538 0.469727C0.819566 0.469727 0.535864 0.615079 0.326689 0.873809C0.117513 1.13254 0 1.48345 0 1.84935C0 2.21525 0.117513 2.56616 0.326689 2.82489C0.535864 3.08362 0.819566 3.22897 1.11538 3.22897H27.8846C28.1804 3.22897 28.4641 3.08362 28.6733 2.82489C28.8825 2.56616 29 2.21525 29 1.84935C29 1.48345 28.8825 1.13254 28.6733 0.873809C28.4641 0.615079 28.1804 0.469727 27.8846 0.469727H1.11538ZM0 9.49998C0 9.13408 0.117513 8.78316 0.326689 8.52444C0.535864 8.26571 0.819566 8.12035 1.11538 8.12035H27.8846C28.1804 8.12035 28.4641 8.26571 28.6733 8.52444C28.8825 8.78316 29 9.13408 29 9.49998C29 9.86587 28.8825 10.2168 28.6733 10.4755C28.4641 10.7342 28.1804 10.8796 27.8846 10.8796H1.11538C0.819566 10.8796 0.535864 10.7342 0.326689 10.4755C0.117513 10.2168 0 9.86587 0 9.49998ZM0 17.1506C0 16.7847 0.117513 16.4338 0.326689 16.1751C0.535864 15.9163 0.819566 15.771 1.11538 15.771H27.8846C28.1804 15.771 28.4641 15.9163 28.6733 16.1751C28.8825 16.4338 29 16.7847 29 17.1506C29 17.5165 28.8825 17.8674 28.6733 18.1261C28.4641 18.3849 28.1804 18.5302 27.8846 18.5302H1.11538C0.819566 18.5302 0.535864 18.3849 0.326689 18.1261C0.117513 17.8674 0 17.5165 0 17.1506Z"
                fill="white"
              />
            </svg>
          </div>
          <h2>Mi Austral</h2>
        </div>
        <div className={`navbar__secondary ${showMenu ? "active" : ""}`}>
          <nav>
            <div>
              <div className="navbar__secondary__user">
                <h3>{user.name} {user.lastName}</h3>
                <h5>{user?.roles?.join(", ")}</h5>
              </div>
              <div className="navbar__secondary__links">
                {renderNavMenu(menu)}
              </div>
            </div>
            <div className="navbar__secondary__logout">
              <a href="" onClick={() => logoutAction()}>
                <img src="assets/img/navbar/cerrar_sesion.svg" alt="" />
                Cerrar Sesión
              </a>
            </div>
          </nav>
        </div>
        <div
          className={`navbar__close ${showMenu ? "active" : ""}`}
          onClick={() => setShowMenu(false)}
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.186279"
              y="20.6924"
              width="29"
              height="3"
              rx="1.5"
              transform="rotate(-45 0.186279 20.6924)"
              fill="white"
            />
            <rect
              x="2.30762"
              y="0.186279"
              width="29"
              height="3"
              rx="1.5"
              transform="rotate(45 2.30762 0.186279)"
              fill="white"
            />
          </svg>
        </div>
      </header>
      <section className="access">
        <div className="container">
          <div className="row">
            {renderMainMenu(menu)}
            <div className="col-12 center">
              <img className="logo" src="assets/img/logo.svg" alt="" />
            </div>
          </div>
        </div>
      </section>
      <div className="dashboard__background">
        <picture>
          <source
            media="(max-width: 991px)"
            srcSet="assets/img/dashboard_mobile.png"
          />
          <source
            media="(max-width: 1366px)"
            srcSet="assets/img/dashboard_notebook.png"
          />
          <img
            className="lazyload"
            loading="lazy"
            src="assets/img/dashboard_desktop.png"
            alt=""
          />
        </picture>
      </div>
    </div>
  );
};
