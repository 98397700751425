import React from "react";

export default ({ loginAction }) => {
  return (
    <div id="login">
      <section className="login">
        <div className="container">
          <img className="logo" src="assets/img/logo.svg" alt="" />
          <div className="login__item">
            <button type="button" className="btn" onClick={() => loginAction()}>
              Ingresar
            </button>
            <a href=""> </a>
          </div>
        </div>
      </section>
      <div className="login__background">
        <picture>
          <source
            media="(max-width: 991px)"
            srcSet="assets/img/login_mobile.png"
          />
          <source
            media="(max-width: 1366px)"
            srcSet="assets/img/login_notebook.png"
          />
          <img
            className="lazyload"
            loading="lazy"
            src="assets/img/login_desktop.png"
            alt=""
          />
        </picture>
      </div>
    </div>
  );
};
