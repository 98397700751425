export const tokenStorageKey = "mi-austral-jwt";

export const getBaseUri = async () => {
  const response = await fetch(`assets/config/config.json`, {
    method: "GET",
  });

  var json = await response.json();

  return json["api_url"];
};

export const getMsalConfig = async () => {
  const response = await fetch(`assets/config/config.json`, {
    method: "GET",
  });

  var json = await response.json();

  return json["msal_config"];
};

export const getScopes = async () => {
  const response = await fetch(`assets/config/config.json`, {
    method: "GET",
  });

  var json = await response.json();

  return json["scopes"];
};
